.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    height: 64px;
    padding: 0 10px;
}

.left {
    font-family: 'Montserrat';
    font-style: normal;

    h4 {

        font-weight: 500;
        font-size: 16px;
        line-height: 135%;
        /* identical to box height, or 22px */


        /* txt and icon 1 */

        color: #363949;
    }

    p {

        font-weight: 400;
        font-size: 14px;
        line-height: 135%;
        /* identical to box height, or 19px */


        /* txt and icon 2 */

        color: rgba(19, 26, 47, 0.7);

    }
}

.right {}

@media screen and (max-width: 767px) {
    .left {
        h4 {
            font-size: 15px;
            line-height: 135%;
        }
    
        p {
            font-size: 10px;
        }
    }
  }