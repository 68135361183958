.root {
    background: #FFFFFF;
    border: 1.5px solid #ECE9F1;
    border-radius: 2.78189px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    cursor: pointer;
    transition: all 1s;

    &:hover {
        box-shadow: 0 0 3px rgba(0, 0, 0, .2);

    }
}