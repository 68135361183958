@import '/src/styles/config.scss';

.calendarBlock {
  
    position: relative;
}

.root {

}
