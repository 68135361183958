@import '/src/styles/config.scss';

.button {
    display: flex;
    height: 52px;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    border: none;
    padding: 1px 60px;
    justify-content: center;
    &:disabled {
        pointer-events: none;
        background: #94959a;
    }

    &:hover {
        transform: scale3d(1.01, 1.01, 1.01);
        transition: transform 0.3s;
    }
}

.defaultColor {
    background: $color-purple;
    color: #fff;
}



.primaryColor {
    background: #A92525;
    color: #fff;

    &:active {}
}

.secondaryColor {
    background: #fff;
    border: 1.5px solid rgba(0, 0, 0, 0.1);
    color: $color-font-color;
    transition: .2s;

    &:hover {
        // box-shadow: 0 0 6px rgba(0, 0, 0, .2);
    }
}

.lightColor {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    border: 1.5px solid rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.7);
    background: transparent;
}

.cancelColor {
    border: 1px solid #A92525;
}

.mainColor {
    margin-top: 24px;
    height: 32px;
    border: 1px solid $color-grey;
    color: $color-font-color;
    padding: 0 16px;
    font-size: 12px;
    background: #fff;
    border-radius: 2px;

    &:hover {
        // color: $color-font-color;
    }

}

$contained-border-radius: 8px;

.containedVariant {
    border-radius: $contained-border-radius;
    position: relative;

    &:before,
    &:after {
        content: '';
        position: absolute;
        border-radius: $contained-border-radius;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.25s ease;
    }

    &:before {
        // background: linear-gradient(267.32deg, #4ebefc 0%, $color-primary 100%);
        z-index: 1;
    }

    &:hover:before {
        // opacity: 1;
    }

    &:after {
        // background: #1aa5f1;
        z-index: 2;
    }

    &:active:after {
        opacity: 1;
    }
}

.lightColor.containedVariant {
    transition: all 0.25s ease;

    &:before,
    &:after {
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
    }

    &:hover {
        // color: $color-font-color;
        // box-shadow: 0 0 6px rgba(0, 0, 0, .2);
    }

    &:active {
        // box-shadow: 0 0 2px rgba(0, 0, 0, .2);
    }
}

.mainColor.containedVariant {
    transition: all 0.25s ease;

    &:before,
    &:after {
        top: -2px;
        left: -2px;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
    }

    &:hover {
        // color: $color-font-color;
        // box-shadow: 0 0 6px rgba(0, 0, 0, .2);
    }

    &:active {
        // box-shadow: 0 0 2px rgba(0, 0, 0, .2);
    }
}

.linkVariant {
    background: none;
    color: $color-font-color;
    text-decoration: underline;
    padding: 0;
    font-weight: normal;
}

.delete {
    background: rgba(253, 68, 56, 0.1);
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;

    /* Error / [day] */

    color: #FD4438;
}

.contain {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    position: relative;
    z-index: 1;

}

.disables {
    border-color: rgba(0, 0, 0, 0.3) ;
     span {
         word-wrap: break-word;
         white-space: nowrap; 
        color: rgba(0, 0, 0, 0.3) ;
    }
}