@import '/src/styles/config.scss';

.root::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa; /* or add it to the track */
  }

  .root {
    // overflow-y: scroll;
    flex: 1;
  }