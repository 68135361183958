.table {
  width: 100%;
  background: #ffffff;
  font-family: "Montserrat";
  border-radius: 6px;
  border: none;

  &:nth-child(1) {
    margin-top: 4px;
  }
}

.bodyItem:nth-child(3) {
  // width: 200px!important;
}

.top {
  margin-bottom: 4px;
}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr 1fr;
  height: 20px;
}

.headerLeft {
  display: flex;
  gap: 15px;
  align-items: center;
}

.filterButton {
  padding: 4px 5px !important;
  border: 1px solid #c3c5cf;
  font-weight: 700;
  background-color: rgba($color: #6178e6, $alpha: 0.1);
}

.date {
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;

  color: rgba(33, 33, 33, 0.5);
}

.headerItem {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height, or 153% */

  /* Grey/40 */
  padding-left: 9px;
  color: #a2a3a5;
}

.body {
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
}

.tr {
  width: 100%;
  padding: 5px 0 5px 0;
  border: 1px solid rgba(0, 0, 0, 0.04);
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr 1fr;
  align-items: center;
}

.bodyItem {
  height: 100%;
  padding: 0 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #363949;
  display: flex;
  align-items: center;

}

.firstName {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #363949;
}

.lastName {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  /* identical to box height, or 139% */

  text-align: center;

  /* txt and icon 2 */

  color: rgba(19, 26, 47, 0.7);
}

.gosNumber {
  text-transform: uppercase;
}

.cost {
  padding-right: 24px;
  display: flex;
  justify-content: end;
  height: 48px;
}

.root {
  border-radius: 6px;
  background-color: #eef0f3;
  padding: 17px;
}

.content {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.06);
  padding: 16px;
}

.scrollBody {
  width: 100%;

  overflow-y: hidden;
}

.tableSum {
  margin-top: auto;
  height: 48px;

  background: #ffffff;

  box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.06);
  border-radius: 6px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  transition: all 1s;
}

.result {
  @extend .bodyItem;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 135%;
  /* identical to box height, or 22px */

  color: rgba(0, 0, 0, 0.5);
}

.resultItem {
  @extend .bodyItem;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  /* identical to box height, or 22px */

  color: #000000;
}

::-webkit-scrollbar {
  display: none;
}

.export {
  // box-shadow: 0 0 2px rgba(0, 0, 0, .2);

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;

  color: #212121;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 14px;
}

.button {
  width: 100%;
  margin-top: 24px;
}

.modal {
  overflow: hidden;
}

.scrollBlock {
  transition: all 1s;
  max-height: calc(100vh - 400px);
  max-height: unset;
}

.datePickerGroup {
  display: flex;
  align-items: center;
}

.datePicker {
  display: flex;
  align-items: center;
}

.iconCalendar {
  position: relative;
  left: -40px;
}

@media screen and (max-width:767px) {
  .root {
    min-width: 777px;
  }

  .content {
    min-width: 737px;
    overflow: scroll;
  }

  .headerRight {
    flex-wrap: wrap;
  }
}