@import '/src/styles/config.scss';

.addBtnTaxt {
    margin-left: 15px;
    white-space: nowrap;
    // text-
}

.addBtn {
    // display: flex;
    padding: 0 10px;
}