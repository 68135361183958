@import '/src/styles/config.scss';

.root {
    font-family: 'Montserrat';
}

@media screen and (max-width: 1440px) and (min-width:768px) {
    .root {
        font-size: 12px !important;
    }
}

@media screen and (max-width:767px) {
    .root {
        font-size: 13px !important;
    }
}