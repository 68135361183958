.modal {
  padding: 60px 20px 30px 30px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 13px;
  margin-bottom: 6px;
  margin-top: 10px;
}

.input {
  font-weight: 400;
  font-size: 14px;
  height: 28px;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  column-gap: 10px;
  margin-top: 24px;
}

.buttonSubmit {
  padding: 1px 40px;
}

.buttonCancel {
  padding: 1px 40px;
  opacity: .4;
  background-color: black;
}

@media screen and (max-width: 767px) {
  .buttonSubmit {
    padding: 1px 17px;
  }
  
  .buttonCancel {
    padding: 1px 20px;
    opacity: .4;
    background-color: black;
  }

  .buttonContainer {
    column-gap: 10px;
    justify-content: center;
  }
}