@import "/src/styles/config.scss";

.cardInfoWrapper {
    background: #ffffff;
    pointer-events: painted;
    cursor: pointer;

    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.08);
        box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.06);
        z-index: 2;
    }
}

.left {
    display: flex;
    justify-content: start;
}

.rigth {
    display: flex;
    justify-content: end;
}

.modal {
    width: 386px;
    height: 164px;
    padding: 0;
    padding-top: 16px;
    padding-bottom: 30px;
    text-align: center;

    span {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        /* or 125% */

        text-align: center;

        /* txt and icon 1 */

        color: #363949;
        margin-bottom: 30px;
    }

    p {
        color: #fe435b;
        font-size: 16px;
    }

    .btnWrapper {
        margin-top: 30px;
        display: flex;
        justify-content: center;

        .btn+.btn {
            margin-left: 20px;
        }

        .btnClose {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */

            text-align: center;
            width: 153px;
            height: 48px;
            color: #ffffff;
            background: #fe435b;
            border-radius: 8px;
            border: none;
            outline: none;
        }

        .btnDel {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */

            text-align: center;
            width: 153px;
            height: 48px;
            color: #fe435b;
            background: rgba(254, 67, 91, 0.2);
            border-radius: 8px;
            border: none;
            outline: none;
        }
    }
}

.modalHard {
    width: 486px;
    height: 199px;
    background: #ffffff;
    border-radius: 16px;
    padding: 3px;

    font-family: "Montserrat";
    text-align: center;
    padding: 0 !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;

    // z-index: 2000;
    h3 {
        color: #363949;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        /* identical to box height, or 22px */

        color: #000000;
        margin-top: 16px;
    }

    .brtnWrapperHard {
        display: flex;
        column-gap: 10px;
        margin-top: 24px;
        margin-left: 30px;

        .deleteHard {
            outline: none;
            border: none;
            width: 208px;
            height: 48px;
            left: 218px;

            border-radius: 8px;
            background: #f3f5f7;

            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
            /* or 19px */

            color: rgba(4, 7, 28, 0.8);
        }

        .closeHard {
            outline: none;
            border: none;
            width: 208px;
            height: 48px;
            left: 218px;
            background: #fe435b;

            border-radius: 8px;

            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;

            color: #ffffff;
        }
    }
}

.name {
    overflow-wrap: break-word;
}

.cell {
    vertical-align: middle;
    min-width: 100px;
    padding: 16px;
    font-family: "SF UI Display";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}

.cellEnd {
    text-align: end;
    padding: 16px;
    font-family: "SF UI Display";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.iconCard {
    text-align: end;
    cursor: pointer;
    // padding-top: 7px;
    height: 100%;
    padding: 10px 20px;
    padding-top: 12px;
    z-index: 90;

    span {
        cursor: pointer;

        path {
            transition: 0.2s;
            stroke: #333;
        }

        margin-left: 20px;

        &:hover {
            path {
                stroke: $color-purple;
            }
        }
    }
}

@media screen and (max-width: 1440px) and (min-width:768px) {
    .cell {
        min-width: 0px;
    }
    
}

@media screen and (max-width:767px) {
    .cell {
        padding: 0px 0 15px 0;
        font-family: "SF UI Display";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
    }

    .cellEnd {
        font-size: 13px;
        padding: 0px;
        min-width: 85px;
    }
}