.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 840px;
}
.topContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.topSiteContainer {
  width: 360px;
}
.topTitle {
  font-size: 13px;
  margin-bottom: 8px;
}
.topTitleHint {
  font-size: 13px;
  margin-bottom: 11.5px;
}
.titleHint {
  margin-top: 27.5px;
  margin-bottom: 11.5px;
}
.bottomRadio {
  margin-top: 7.5px;
}
.button {
  width: 322px;
  margin: 40px auto;
}

.title {
  font-size: 13px;
  margin-top: 24px;
  margin-bottom: 8px;
}
.titleHint {
  font-size: 13px;
  margin-top: 27.5px;
  margin-bottom: 11.5px;
}

.inputContainer {
  margin-top: 8px;
}
.input {
  font-weight: 400;
  font-size: 14px;
  height: 32px;
}

.textArea {
  font-weight: 400;
  font-size: 14px;
  height: 64px;
}

.hint {
  top: 24px;
  position: relative;
}

.checkBox {
  margin-top: 8px;
  margin-left: 3px;
}

.inputButtons {
  height: 24px;
  padding-left: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.plusButton {
  background-color: "#F3F5F7";
  border: 0;
  height: 24px;
  width: 24px;
  padding: 0;
  border-radius: 3px;
}

.deleteButton {
  margin-top: 48px;
}

.autocapitolize {
  text-transform: uppercase;
}

.stationBans {
  overflow-y: scroll;
  max-height: 240px;
}
@media screen and (max-width: 1440px) and (min-width:768px) {
  .container {
    max-width: 840px;
    width: 100%;
  }
  .topSiteContainer {
    width: 48%;
  }
  
}
@media(max-width:767px) {
  .topContainer {
    flex-direction: column;
    gap: 30px;
  }
  .hint {
    position: absolute;
    top: 1351px;
    font-size: 4px;
    left: 26px;
  }
  .topSiteContainer {
    width: 90%;
    margin: 0 auto;
    display: block;
  }
  .button {
    width: auto;
  }
}