.root {
    margin-bottom: 30px;
    width: 100%;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width:767px) {
    .root {
        margin-bottom: 20px!important;
    }
}