.table {
  width: 100%;
  background: #ffffff;
  font-family: "Montserrat";
  box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.06);
  border-radius: 6px;
  border: none;

  &:nth-child(1) {
    margin-top: 4px;
  }
}

.boxShadowNone {
  box-shadow: none;
}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 20px;
}

.headerLeft {
  display: flex;
  gap: 15px;
  align-items: center;
}

.date {
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;

  color: rgba(33, 33, 33, 0.5);
}

.headerItem {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height, or 153% */

  /* Grey/40 */
  padding-left: 9px;
  color: #a2a3a5;
}

.body {
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
}

.tr {
  width: 100%;
  max-height: 48px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
}

.bodyItem {
  height: 100%;
  padding: 0 12px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #363949;
  display: flex;
  align-items: center;
}

.firstName {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #363949;
}

.lastName {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  /* identical to box height, or 139% */

  text-align: center;

  /* txt and icon 2 */

  color: rgba(19, 26, 47, 0.7);
}

.gosNumber {
  text-transform: uppercase;
}

.cost {
  padding-right: 24px;
  display: flex;
  justify-content: end;
  height: 48px;
}

.root {
  border-radius: 6px;
  background-color: #eef0f3;
  padding: 17px;
}

.content {
  height: 100%;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.06);
  padding: 16px;
}

.headerTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */

  color: #04071c;
}

.headerContainer {
  margin-top: 15px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scrollBody {
  width: 100%;
  max-height: 230px;
  overflow-y: hidden;
}

.white {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  color: transparent;
}

.tbody {
  height: 200px;
  // display: inline-block;
  width: 100%;
  overflow-y: auto;
}

.tableSum {
  margin-top: 10px;
  height: 48px;

  background: #ffffff;

  box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.06);
  border-radius: 6px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  transition: all 1s;
}

.result {
  @extend .bodyItem;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 135%;
  /* identical to box height, or 22px */

  color: rgba(0, 0, 0, 0.5);
}

.resultItem {
  @extend .bodyItem;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  /* identical to box height, or 22px */

  color: #000000;
}

::-webkit-scrollbar {
  display: none;
}

.export {
  // box-shadow: 0 0 2px rgba(0, 0, 0, .2);

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;

  color: #212121;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 14px;
}

.button {
  width: 100%;
  margin-top: 24px;
}

.modal {
  overflow: hidden;
}

.scrollBlock {
  max-height: 200px;
  transition: all 1s;
}

@media screen and (max-width: 1440px) and (min-width:768px) {
  .scrollBlock {
    max-height: unset;
    overflow: scroll;
  }


}


@media (max-width: 767px) {
  .table {}

  .scrollBlock {
    max-height: 240px;
    transition: all 1s;
    overflow: scroll;
  }

  .header {
    display: block;
    height: auto;

    .headerLeft,
    .headerRight {}

    .headerItem {}
  }

  .body {}

  .tr {}

  .bodyItem,
  .firstName,
  .lastName,
  .cost {
    padding-right: 10px !important;
    font-size: 14px;

  }

  .cost {}

  .content {}

  .headerTitle {}

  .scrollBody,
  .tbody {}

  .tableSum {}

  .button {}

  .modal,
  .scrollBlock {}

  ::-webkit-scrollbar {}
}