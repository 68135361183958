.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7.5px 34px;
  gap: 8px;
  background: #f3f5f7;
  border-radius: 8px;
  border: 1px solid rgba(151, 151, 151, 0.4);
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #363849;
}
