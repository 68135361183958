.title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */

    color: #04071c;
    margin-bottom: 10px;
}

.top {
    margin-bottom: 4px;
}

.filterButton {
    margin-bottom: 14px;
}

.historyWrapper {
    width: 100%;
}

.table {
    width: 100%;
}

.line {
    border: 1px solid rgba(0, 0, 0, 0.04);
    height: 48px;
}

.thead {
    margin-bottom: 4px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.lineItem {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height, or 128% */

    /* txt and icon 1 */
    vertical-align: middle;
    padding: 0 12px;
    color: #363949;
    max-width: 200px;
    min-width: 150px;
    display: flex;
    align-items: center;
}

.tableHeaderLine {}

.tableHeaderLineItem {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    /* identical to box height, or 153% */
    max-width: 200px;
    min-width: 150px;
    padding: 0 12px;
    /* Grey/40 */

    color: #a2a3a5;
    text-align: start;
}

.tbody {
    width: 100%;
}

.headerItemLineEnd {
    text-align: end;
}

.wrapperPagination {
    text-align: center;
}

.leftHeader {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
}

.date {
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;

    color: rgba(33, 33, 33, 0.5);
}

.bodyItem {
    height: 100%;
    padding: 0 12px;
    vertical-align: middle;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #363949;
}

.tableSum {
    width: 100%;
}

.result {
    @extend .bodyItem;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    /* identical to box height, or 22px */

    max-width: 200px;
    min-width: 150px;
    color: rgba(0, 0, 0, 0.5);
}

.white {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    color: transparent;
}

.resultItem {
    @extend .bodyItem;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 135%;
    /* identical to box height, or 22px */

    color: #000000;
    max-width: 200px;
    min-width: 150px;
}

.grayWrapper {
    background-color: #eef0f3;
    margin-left: -18px;
    margin-right: -18px;
    padding: 17px;
    padding-top: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.wrapperTable {
    margin-top: 10px;
    height: 48px;

    background: #ffffff;
    /* card shadow dsh board */

    box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.06);
    border-radius: 6px;
    width: 100%;

    padding-left: 8px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.root {
    border-radius: 6px;
    background-color: #eef0f3;
    padding: 17px;
}

.content {
    height: 100%;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.06);
    padding: 16px;
}

@media screen and (max-width: 1440px) and (min-width:768px) {
    .tableHeaderLineItem {
        min-width: 10px;
    }
  
  }
  
@media screen and (max-width:767px) {
    .tableHeaderLineItem {
        min-width: 0px;
    }
}