@import "/src/styles/config.scss";

.root {
  position: relative;
}

.inputWrapper {
  width: 100%;
  min-height: $element-height;
  border-radius: 8px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  padding: 8px 16px;
}

.input {
  border: none;
  width: 100%;
  display: flex;
  flex: 1;
  font-weight: 400;
  background: transparent;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 14px;
  line-height: 130%;
  resize: none;
  min-height: 48px;
  max-height: 146px;

  /* txt and icon 1 */
  color: #363949;

  &::placeholder {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;

    /* identical to box height, or 22px */

    /* txt and icon 3 */
    color: #a2a3a5;
  }
}

.error {
  border: 1px solid $color-purple;
  color: $color-purple;
}

.disabled {
  border: none;
  color: rgba(0, 0, 0, 0.3);
}

.errorText {
  // position: absolute;
  bottom: -20px;
  color: red;
  left: 0;
  display: flex;
  max-width: 100%;
  text-overflow: ellipsis;
  position: absolute;
  bottom: -23px;
  left: 18px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}
