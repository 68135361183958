.filterWrapper {}

.checkItem {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */


    /* txt and icon 1 */

    color: #363949;

}


.button {
    width: 100%;
}

.modal {
    height: 578px;
}