.container {
  background-color: #fff;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  padding-left: 40px;
}

.content {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  transition: all 1s;
}
@media screen and (max-width:767px) {
  .container {
    width: 100%;
    padding-left: 10px;
  }
}