// @import '../../styles/config';
@import '../../styles/app.global';

.app {
    background: #fff;
}

@media (max-width: 1060px) {
    // .app {
    //     min-width: 1060px;
    //     overflow-x: scroll;
    // }
}

* {
    font-family: 'Montserrat' !important;
}