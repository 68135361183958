.pagination {
    // margin-top: 10px;

}



.paginationItem {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    /* identical to box height, or 27px */
    display: inline-block;

    color: rgba(0, 0, 0, 0.5);
    min-width: 50px;
    // margin: 0 21px;
    cursor: pointer;
}

.paginationItemArrowEnd,
.paginationItemArrowStart {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    /* identical to box height, or 27px */

    width: 50px;
    color: #000000;
    cursor: pointer;
}

.activePage {
    color: #76227F;
}