.item {
  height: 170px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #04071c;
  margin-bottom: 12px;
}
.countContainer {
  display: flex;
  gap: 11px;
  align-items: center;
}

.count {
  width: min-content;
  color: #363849;
  text-align: center;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.icon {
  height: 35px;
  width: 35px;
}

.buttons {
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
  column-gap: 16px;
}

.allDrivers {
  padding: 15px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonAllTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}

.addDriver {
  display: flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgba(151, 151, 151, 0.4);
}

.buttonAddTitle {
  color: #363849;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
