.wrapperBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    background: #FFFFFF;
    border: 1.5px solid #76227F;
    border-radius: 3px;

    cursor: pointer;
    transition: all 1s;

    &:hover {

        box-shadow: 0 0 6px rgba(0, 0, 0, .2);

    }
}