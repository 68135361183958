@import '/src/styles/config.scss';

.icon {
    svg {
        stroke: $color-font-color;
        max-height: 30px;
        max-width: 25px;
    }
}

.activeIcon {
    svg {
        stroke: $color-purple;
        max-height: 30px;
        max-width: 25px;
    }
}

.root {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 12px 10px 12px 30px;
    gap: 17px;
    cursor: pointer;
    text-decoration: none !important;

    a {
        display: block;
        margin-left: 12px;
        text-decoration: none;
    }
}

.activeMenu {
    border-right: 2px solid $color-purple;
}

.link {
    color: $color-font-color;
    font-weight: 600;
}

.activeLink {
    font-weight: 600;
}

.text {
    text-decoration: none !important;
}

.pointerNone {
    pointer-events: none;
}
