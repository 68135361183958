.modal {
    width: 738px;
}

.modalBody {
    overflow: hidden;
    transition: all 1s;
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */


    /* txt and icon 1 */

    color: #363949;

}

.wrapperTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropDown {
    margin-top: 6px;
    background: #F7F7F7;
    border-radius: 8px;
    padding: 13px 22px 13px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    // width: 607px;
    overflow-x: auto;
}

.valueDropDown {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    /* identical to box height, or 22px */

    white-space: nowrap;
    /* txt and icon 3 */

    color: #A2A3A5;

    width: 607px;
    overflow-x: auto;
}


.dropDownList {
    max-height: 0px;
    transition: all 1s;
    opacity: 0;
    overflow-y: auto;

}

.activeDropDown {
    overflow-y: auto;
    opacity: 1;
    transition: all 1s;
    max-height: 256px;
}



.arrow {
    transition: all 0.5s;
    transform: rotate(0);
}

.activeArrow {
    transform: rotate(180deg);
}

.buttonWrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.wrapperDropDown{
    transition: all 1s;
}

@media screen and (max-width: 767px) {
    .modal {
        width: 90%;
    }
    .valueDropDown {
        font-size: 14px;
    }
  }