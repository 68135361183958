.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 800px;
  height: 100%;
}

.topContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.topSiteContainer {
  width: 360px;
}

.topTitle {
  font-size: 13px;
  margin-bottom: 8px;
}

.topTitleHint {
  font-size: 13px;
  margin-bottom: 11.5px;
}

.titleHint {
  margin-top: 27.5px;
  margin-bottom: 11.5px;
}

.bottomRadio {
  margin-top: 7.5px;
}

.button {
  width: 322px;
  margin: 0 auto;
}

.title {
  font-size: 13px;
  margin-top: 24px;
  margin-bottom: 8px;
}

.titleHint {
  font-size: 13px;
  margin-top: 27.5px;
  margin-bottom: 11.5px;
}

.input {
  font-weight: 400;
  font-size: 14px;
  height: 32px;
}

.textArea {
  font-weight: 400;
  font-size: 14px;
  height: 64px;
}

.hint {
  top: 24px;
  position: relative;
}

.hintMobile {
  display: none;
}

@media screen and (max-width: 1440px) and (min-width:768px) {
  .container {
    max-width: 840px;
    width: 100%;
  }

  .topSiteContainer {
    width: 48%;
  }

}

@media screen and (max-width:767px) {
  .container {
    width: 100%;
  }

  .topContainer {
    flex-direction: column;
  }

  .button {
    width: auto;
    padding-top: 20px;
    align-items: center;
    flex-direction: column;
    display: flex;
  }

  .topSiteContainer {
    width: 100%;
  }

  .hint {
    display: none;
  }

  .hintMobile {
    margin-bottom: 10px;
    display: block;
  }
}