.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    padding: 0 20px 0 0;
    position: relative;
    overflow: hidden;
}

.inner {
    width: 100%;
    height: 100%;
    max-width: calc(100vw - 120px);
}

@media screen and (max-width: 767px) {
    .container {
        padding: 0;
    }

    .inner {
        max-width: 100%;
    }
}