.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 800px;
  height: 100%;
}
.topContainer {
  width: 340px;
}
.topTitle {
  font-size: 13px;
  margin-bottom: 11.5px;
}
.orgName {
  font-size: 13px;
  margin-top: 27.5px;
  margin-bottom: 11.5px;;
}
.bottomRadio {
  margin-top: 4px;
} 
.button {
  width: 322px;
  margin: 0 auto;
}
.input {
  font-weight: 400;
  font-size: 14px;
  height: 32px;
}
@media screen and (max-width:767px) {
  .container {
    width: 100%;
    min-width: unset;
    padding-left: 10px;
  }
  .topContainer {
    width: 100%;
  }
}