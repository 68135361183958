.mainPageTop {
    display: grid;
    width: 100%;
    font-family: 'Montserrat' !important ;
    gap: 34px;
    grid-template-columns: 1fr 1fr;
}

.mainPageItem {
    display: flex;
    flex-direction: row;
}

.mainPageItem+.mainPageItem {
    margin-left: 34px;
    // max-height: 200px;
}

.mainPageItemWrapper {
    height: 170px;
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */


    color: #04071C;

    margin-bottom: 12px;
}

.price {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    /* identical to box height, or 26px */


    color: rgba(19, 26, 47, 0.9);

    margin-bottom: 12px;
}

.remains {
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */


    color: rgba(19, 26, 47, 0.5);

    margin-bottom: 16px;
}

.notification {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */


    color: rgba(19, 26, 47, 0.5);

}

.button {
    font-family: 'Montserrat';
    width: 220px;
}

// .wrapperBottom {
//     background-color: #F3F5F7;
// }
.mainPageBottom {
    margin-top: 34px;
}

.buttonModal {
    margin-top: 43px;
    width: 100%;
}

.modalRoot {
    height: 196px;
}

@media screen and (max-width: 1440px) and (min-width: 768px) {
    .mainPageTop {
        display: grid;
        width: 100%;
        font-family: 'Montserrat' !important;
        gap: 20px;
        grid-template-columns: repeat(1, 1fr);
    }

    .mainPageItem+.mainPageItem {
        margin-left: 20px;
    }

    .mainPageItemWrapper {
        height: 150px;
    }

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;
        color: #04071C;
        margin-bottom: 12px;
    }

    .price {
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        color: rgba(19, 26, 47, 0.9);
        margin-bottom: 12px;
    }

    .remains {
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        color: rgba(19, 26, 47, 0.5);
        margin-bottom: 16px;
    }

    .notification {
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        color: rgba(19, 26, 47, 0.5);
    }

    .button {
        font-family: 'Montserrat';
        width: 180px;
    }

    .buttonModal {
        margin-top: 20px;
        width: 100%;
    }

    .modalRoot {
        height: 150px;
    }
}

@media screen and (max-width: 767px) {
    .mainPageTop {
        display: grid;
        width: 100%;
        font-family: 'Montserrat' !important;
        gap: 10px;
        grid-template-columns: 1fr;
    }

    .mainPageItem {
        margin-left: 0;

    }

    .mainPageItemWrapper {
        height: 120px;
    }

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 120%;
        color: #04071C;
        margin-bottom: 8px;
    }

    .price {
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        color: rgba(19, 26, 47, 0.9);
        margin-bottom: 8px;
    }

    .remains {
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        color: rgba(19, 26, 47, 0.5);
        margin-bottom: 12px;
    }

    .notification {
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        color: rgba(19, 26, 47, 0.5);
    }

    .button {
        font-family: 'Montserrat';
        width: 100%;
    }

    .buttonModal {
        margin-top: 10px;
        width: 100%;
    }

    .modalRoot {
        height: auto;
    }
}
