@import "../../../styles/config.scss";

.root {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    overflow: hidden;
    background-image: url("../../../assets/images/Bitmap.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    align-items: center;
    justify-content: center;
}

.centerBlock {
    width: 800px;
    height: 562px;
    background: rgba(255, 255, 255, 0.22);
    border: 1.5px solid #ffffff;
    box-sizing: border-box;
    backdrop-filter: blur(18px);
    border-radius: 27px;
    padding: 28px;
}

.border {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 0.22);

    display: flex;
}

.centerInnerBlock {
    padding: 1px;
    overflow: hidden;
    border-radius: 24px;
    background: rgb(253, 20, 32);
    width: 100%;
    height: 100%;
    background: linear-gradient(16deg, #cfb6cf, #9ddfb8);
}

.leftTopText {
    margin-bottom: 6px;
}

.innerLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 50%;
    padding: 24px;
}

.innerRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    height: 100%;
    padding: 24px;
    background: rgba(255, 255, 255, 0.5);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.45) 0%, rgba(255, 255, 255, 0.8925770991990546) 100%);
}

.authText {
    margin-bottom: 35px;
}

.inputHolder {
    margin-bottom: 24px;
}

.inputHolderPass {
    margin-bottom: 20px;
}

.helpBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
}

.redText {
    color: $color-purple;
}

.checkBox {
    display: flex;
    align-items: center;
    column-gap: 16px;
}

.buttonsContainer {
    width: 100%;
}
.enterButton {
    width: 100%;
}
.registerButton {
    width: 100%;
    background: #00ab4d;
    margin-top: 16px;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .btnWrapper {
        width: 326px;
        display: flex;
        align-items: end;

        button {
            width: 100%;
        }
    }
}

@media screen and (max-width:767px) {
    .innerLeft {
        display: none;
    }
    .innerRight {
        width: 100%;
    }
  }