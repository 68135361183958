.line {
    border: 1px solid rgba(0, 0, 0, 0.04);
    height: 48px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    align-items: center;
}

.lineItem {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height, or 128% */



    padding: 0 12px;
    color: #363949;
    max-width: 200px;

}

.lineItemEnd {
    // text-align: end;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* or 128% */

    text-align: end;

    /* txt and icon 1 */

    color: #363949;
}