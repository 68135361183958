@import '/src/styles/config.scss';

.root {
    background-color: #fff;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    padding-left: 248px;
}

.left {
    position: fixed;
    top: 0;
    left: 0;
    width: 248px;
    // padding-left: 33px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #f1f1f1;
    height: 100%;
    z-index: 5;
}

.right {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    transition: all 1s;
}

.logo {
    // width: 100px;
    padding: 11px 20px;
}

.block {
    display: flex;
}

// fix z-index
.top {
    min-height: 90px;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;


}

.leftMenuWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 0;
    flex: 1
}

.menu {
    // padding-top: 50px;
}

.logOutBlock {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logOut {
    cursor: pointer;
}

.innerContent {
    display: flex;
    flex: 1;
    margin: 10px;
    overflow: hidden;
    // overflow-y: auto;
    overflow-y: hidden;
}

.mobileMenu {
    display: none;
}

@media (max-width: 1060px) {
    .right {
        // min-width: 1060px;
        overflow-x: hidden;
        // width: 50%;
    }

    ::-webkit-scrollbar {
        display: block;
    }
}

@media (max-width: 767px) {
    .root {
        position: absolute;
        padding: 0;
    }

    .left {
        background-color: white;
        z-index: 2222;
        width: 73%;
    }

    .mobileMenu {
        display: block;
        position: absolute;
        z-index: 3333;
        left: 20px;
        top: 30px;
    }

    .mobileMenuHide {
        display: none;
    }

    .mobileMenuClose {
        display: block;
        position: fixed;
        z-index: 3333;
        left: 20px;
        top: 30px;
    }

    .top {
        width: 61%;
        text-align: center;
        font-size: 11px;
        line-height: 14px;
        align-self: center;
    }
}

@media screen and (max-width: 1440px) and (min-width:768px) {
    .innerContent {
        overflow: scroll;
    }

    .top {
        max-width: 80%;
        margin: 0 auto;
        text-align: center;
    }
}