.root {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 200;
}

.border {
  width: 600px;
  height: 370px;
  background: rgba(255, 255, 255, 0.22);
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  backdrop-filter: blur(18px);
  border-radius: 27px;
  padding: 18px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1px;
  overflow: hidden;
  border-radius: 18px;
  background: rgb(253, 20, 32);
  width: 100%;
  height: 100%;
  background: linear-gradient(16deg, #cfb6cf, #9ddfb8);
}

.title {
  max-width: 70%;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 26px;
  text-align: center;
  white-space: pre-line;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  margin-top: 50px;
  gap: 16px;
}

.disagree {
  background-color: #00ab4d;
}
