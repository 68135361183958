.editWrapper {
  display: flex;
  min-height: 738px;
}

.left {
  width: 100%;
}

.right {
  width: 100%;
}

.list {
  max-height: 192px;
  overflow-y: auto;
}

.unavaibleGas {
  margin-top: 30px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;

  color: #363949;
}

.buttonWrapper {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.button {
  width: 100%;
  max-width: 325px;
}
