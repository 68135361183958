@font-face {
    font-family: 'Montserrat';
    src: 
        url('../assets/fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: 
        url('../assets/fonts/Montserrat/Montserrat-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: 
        url('../assets/fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: 
        url('../assets/fonts/Montserrat/Montserrat-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: 
        url('../assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: 
        url('../assets/fonts/Montserrat/Montserrat-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: 
        url('../assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: 
        url('../assets/fonts/Montserrat/Montserrat-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: 
        url('../assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: 
        url('../assets/fonts/Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: 
        url('../assets/fonts/Montserrat/Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: 
        url('../assets/fonts/Montserrat/Montserrat-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: 
        url('../assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: 
        url('../assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: 
        url('../assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: 
        url('../assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: 
        url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: 
        url('../assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: 
        url('../assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: 
        url('../assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: 
        url('../assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: 
        url('../assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: 
        url('../assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: 
        url('../assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}
