.tableBody,
.saleTable {
    max-width: 100%;
}

.headerContainer {
    display: flex;
    align-items: center;
    gap: 62px;
}

.createDriverButton {
    height: 36px !important;
    width: 240px;
    padding: 0 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.createDriverText {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    margin-right: 38px;
}

.plusText {
    font-size: 25px;
    font-weight: 500;
}

.hintText {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    align-items: center;
    max-width: 440px;
    margin-right: 20%;
    color: #979797;

    @media screen and (min-width: 1600px) {
        max-width: 330px;
        margin-right: 0%;
    }
}

.table {
    width: 100%;
}

.cell {
    text-align: start;
    padding: 16px;
}

.cellEnd {
    padding: 16px;
    text-align: end;
}

.headerTable {
    height: 30px;
    color: grey;
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}

.settingSearch {
    width: 327px !important;
}

.header {
    display: grid;
    grid-template-columns: 15% 21% 22% 13% 17%;
}

.wrapperScroll {
    display: flex;
    flex: 1;
    flex-direction: column;
}

@media screen and (max-width: 1440px) and (min-width:768px) {
    .headerContainer {
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width: 1165px) {
    .header {
        grid-template-columns: 15% 22% 19% 14% 20%;
    }
}

@media (max-width: 1040px) {
    .header {
        grid-template-columns: 15% 22% 19% 14% 20%;
    }
}

@media screen and (max-width:767px) {
    .headerContainer {
        flex-direction: column;
        gap: 12px;
    }

    .header {
        grid-template-columns: 80px 86px 60px 120px 81px;
        gap: 16px;
    }

    .hintText {
        margin: 10px;
    }

    .table {
        padding: 0;
    }

    .cell {
        padding: 0;
    }

    .cellEnd {
        padding: 0 0 20px 0;
    }
}