@import '/src/styles/config.scss';

.root {
    padding: 38px 20px 30px 38px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.title {
    font-weight: 700;
    font-size: 32px;
    color: #04071C;
    opacity: 80%;
}

.content {
    position: relative;
    flex: 1;
    overflow: hidden;
    scroll-margin: 10px;
    scrollbar-color: $color-purple rgb(247, 241, 241);
    scrollbar-width: thin;
    display: flex;
    flex-direction: column;
    // overflow-y: auto;
}

.top {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 38px;
    // position: sticky;
    top: 40px;
    z-index: 9;
    margin-bottom: 30px;
    background-color: #fff;
}

.leftHeader {
    display: flex;
    gap: 16px;
    align-items: center;
}

@media screen and (max-width: 1440px) and (min-width:768px) {
    .content {
        overflow-x: scroll;
    }
    .leftHeader {
        flex-direction: column;
        gap: 12px;
    }
    .top {
        gap: 7px;
        justify-content: center;
    }
}

@media(max-width:767px) {
    .title {
        font-size: 18px;
    }

    .root {
        padding: 10px;
        overflow: hidden;
    }

    .top {
        flex-direction: column;
    }

    .leftHeader {
        flex-direction: column;
        gap: 12px;
    }

    .content {
        overflow-x: scroll;
        max-width: 580px;
    }
}