.container {
  display: flex;
  align-items: center;
}
.title {
  align-items: center;
  font-size: 14;
  font-weight: 600;
  color: #979797;
  margin-right: 4px;
}